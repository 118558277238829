import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import store from '../store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/inicio-sesion',
    name: 'Inicio-sesion',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Inicio-sesion.vue')
  }
  ,
  {
    path: '/registro',
    name: 'registro',
    component: () => import(/* webpackChunkName: "about" */ '../views/registro.vue')
  }
  ,
  {
    path: '/ingreso-factura',
    name: 'Ingreso factura',
    component: () => import(/* webpackChunkName: "about" */ '../views/ingreso-factura.vue'),
    beforeEnter: (to, from, next) => {
      if (store.state.autenticado) {
        next()
      } else {
        next({
          name: "Inicio-sesion" // back to safety route //
        });
      }
    }
  }
  ,
  {
    path: '/jugar',
    name: 'jugar',
    component: () => import(/* webpackChunkName: "about" */ '../views/jugar.vue'),
    beforeEnter: (to, from, next) => {
      if (store.state.autenticado) {
        next()
      } else {
        next({
          name: "Inicio-sesion" // back to safety route //
        });
      }
    }
  }
  ,
  {
    path: '/forgotpassword',
    name: 'forgotpassword',
    component: () => import(/* webpackChunkName: "about" */ '../views/forgotpassword.vue')
  }
]

const router = new VueRouter({
  routes
})

export default router
