<template>
  <div class="home">
    <section id="first" class="pt-4 relative">
      <div class="logo ml-10">
        <router-link to="/"
          ><img class="logo-image" src="../assets/logo-tablita.svg"
        /></router-link>
      </div>
      <div class="inner-container px-4 pt-4 pb-2 relative z-50">
        <div class="grid md:grid-cols-2 gap-12 md:px-20 px-4 md:items-center">
          <div class="logo md:mt-4">
            <img src="../assets/25_aniversario_tablita.png" />
          </div>
          <div class="px-10">
            <img src="../assets/gracias_participar.png" />
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: "Home",
  components: {
    // HelloWorld
  },
  methods: {
    gotoIngresa() {
      this.$store.state.redirect = "/ingreso-factura";
      this.$router.push("/ingreso-factura");
    },
    gotoRegistro() {
      this.$router.push("/registro");
    },
    gotoJackpot() {
      this.$store.state.redirect = "/jugar";
      this.$router.push("/jugar");
    },
  },
};
</script>
<style scoped>
#first {
  background: url("../assets/fondo-landing.jpg");
  overflow: hidden;
  min-height: 100vh;
}
#second {
  background: #0e0e0d;
  position: relative;
}
#third {
  background: #dc6a06;
}
.home {
}
.logo-image {
  max-width: 180px;
}
.inner-container {
  max-width: 1240px;
  margin: 0 auto;
}
.decorator1 {
  max-width: 200px;
}
.decorator2 {
  bottom: -90px;
}
.decorator3 {
  top: -90px;
  max-width: 200px;
}
.premios img {
  width: 75%;
  margin: 0 auto;
}
nav {
  position: absolute;
  top: 53px;
  right: 100px;
  background: #dc6a06;
  border-radius: 2px;
  z-index: 100;
  padding: 5px 20px;
}
nav ul > li {
  display: inline-block;
  margin: 0 15px;
  color: white;
  font-weight: bold;
  font-size: 13px;
  cursor: pointer;
}
a {
  cursor: pointer;
}
.listado-pasos {
  max-width: 700px;
  margin: 20px auto;
}
.listado-pasos li {
  margin: 20px 0;
}
.listado-icono {
  max-height: 60px;
  margin-right: 20px;
}
.listado-numero {
  max-height: 80px;
}
h2 {
  color: #231f20;
  font-size: 34px;
  font-weight: bold;
}
.pasos-participar h3 {
  margin-top: 8px;
  text-align: left;
  color: #231f20;
  font-size: 30px;
  font-weight: bold;
  line-height: 23px;
}
.pasos-participar span {
  display: block;
  text-align: left;
  color: white;
  font-size: 20px;
  font-weight: 100;
}
#third h3 {
  color: #231f20;
  font-size: 22px;
}
.jackpot-premio {
  font-weight: 500;
  font-size: 14px;
  display: block;
  line-height: 15px;
  margin-top: 10px;
}
.premios-jackpot {
  flex-wrap: wrap;
  width: 1080px;
  margin: 0 auto;
}
.premios-jackpot > div {
  width: 150px;
  margin: 15px 5px;
}

.premios-jackpot img {
  width: 85%;
}
@media only screen and (max-width: 767px) {
  .premios {
    /* flex-direction: column; */
  }
  .premios > div {
    width: 140px;
  }
  .premios img {
    width: 60%;
    margin: 15px auto;
  }
  h2 {
    font-size: 24px;
    font-weight: bold;
  }
  .pasos-participar h3 {
    font-size: 17px;
  }
  .pasos-participar span {
    font-size: 14px;
  }
  .listado-icono {
    max-height: 35px;
    margin-right: 10px;
  }
  .listado-numero {
    max-height: 50px;
  }
  .premios-jackpot {
    width: 100%;
  }
}
</style>
