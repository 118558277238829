import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/tailwind.css'
import firebase from "firebase";
import { createProvider } from './vue-apollo'
import VueAnalytics from 'vue-analytics'

var firebaseConfig = {
  apiKey: "AIzaSyCQ1FuvdZGC2vbVM8xhrRXob4F_LN_kG3k",
  authDomain: "tablita-25-app.firebaseapp.com",
  projectId: "tablita-25-app",
  storageBucket: "tablita-25-app.appspot.com",
  messagingSenderId: "483223260775",
  appId: "1:483223260775:web:4152f788391881f4c54ec2"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);

Vue.config.productionTip = false
Vue.use(VueAnalytics, {
  id: 'UA-208187412-1',
  router
})
new Vue({
  router,
  store,
  apolloProvider: createProvider(),
  render: h => h(App)
}).$mount('#app')
